import React from "react"
import Layout from "../components/layout/layout-home"
import Hero from "../components/charities/hero"
import Charities from "../components/charities/charities"
import { Box } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

const CharitiesPage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        charityOfTheMonth {
          title
          subtitle
          description
          charities {
            year
            logos {
              url
              imageFile {
                childImageSharp {
                  fixed(width: 150) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    title,
    description,
    subtitle,
    charities,
  } = data.strapi.charityOfTheMonth
  return (
    <Layout>
      <Box height="110px" />
      <Hero title={title} subtitle={subtitle} description={description} />
      <Charities charities={charities} />
    </Layout>
  )
}

export default CharitiesPage
