import { Typography, Box, Button, makeStyles } from "@material-ui/core"
import React from "react"

import Fade from "react-reveal/Fade"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    minHeight: "40vh",
    justifyContent: "center",
    padding: "96px 0",
    backgroundColor: "#E6E6E6",
    width: "100%",
    [theme.breakpoints.down("xs")]: { padding: "48px 0" },
  },
}))

const Hero = ({ title, subtitle, description }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Fade>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          minHeight="30vh"
          margin="0 24px"
          maxWidth="1000px"
        >
          <Typography
            variant="h1"
            color="white"
            style={{ marginBottom: "32px", width: "100%" }}
          >
            <span
              style={{
                backgroundColor: "#f26522",
                padding: "0 8px",
                textTransform: "uppercase",
              }}
            >
              {title}
            </span>
          </Typography>
          <Typography
            variant="h3"
            color="primary"
            style={{ marginBottom: "48px", width: "100%", color: "#000" }}
          >
            {subtitle}
          </Typography>
          <Typography variant="body1" style={{ color: "#000" }}>
            {description}
          </Typography>
        </Box>
      </Fade>
    </Box>
  )
}

export default Hero
