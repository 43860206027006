import { Typography, Box, makeStyles } from "@material-ui/core"
import React from "react"
import Fade from "react-reveal/Fade"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    minHeight: "40vh",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gridGap: "96px",
    padding: "96px 0",
    backgroundColor: "#000",
    width: "100%",
    [theme.breakpoints.down("xs")]: { padding: "0", gridGap: "8px" },
  },
  year: {
    display: "flex",
    gridGap: "32px",
    flexWrap: "wrap",
    padding: "32px",
    [theme.breakpoints.down("xs")]: { padding: "48px 24px", gridGap: "16px" },
  },
  logo: {
    width: "100%",
    padding: "32px",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: { padding: "16px" },
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    aspectRatio: "1/1",
    backgroundColor: "#777",
    height: "100%",
  },
  logoGrid: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: "16px",
    [theme.breakpoints.down("sm")]: { gridTemplateColumns: "1fr 1fr 1fr" },
    [theme.breakpoints.down("xs")]: { gridTemplateColumns: "1fr 1fr " },
    gridAutoColumns: "200px",
    justifyItems: "center",
  },
}))

const Charities = ({ charities }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      {charities.map(charity => (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          minHeight="30vh"
          margin="0 24px"
          maxWidth="1000px"
          width="100%"
        >
          <Fade>
            <Box className={classes.year}>
              <Typography
                variant="h1"
                color="white"
                style={{ marginBottom: "24px", width: "100%" }}
              >
                <span
                  style={{
                    backgroundColor: "#f26522",
                    padding: "0 8px",
                  }}
                >
                  {charity.year}
                </span>
              </Typography>
              <Box className={classes.logoGrid}>
                {charity.logos.map(logo => {
                  if (logo.imageFile.childImageSharp !== null) {
                    return (
                      <Box className={classes.logoContainer}>
                        <Img
                          className={classes.logo}
                          fixed={logo.imageFile.childImageSharp.fixed}
                          imgStyle={{ objectFit: "contain" }}
                          placeholderStyle={{ objectFit: "contain" }}
                        />
                      </Box>
                    )
                  }
                })}
              </Box>
            </Box>
          </Fade>
        </Box>
      ))}
    </Box>
  )
}

export default Charities
